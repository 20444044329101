import { useStore } from '@/store/store';
import { Clipboard } from '@capacitor/clipboard';
import { accessToken } from '@/services/Authentication';
import AppAPI from './AppAPI';
import { Browser } from '@capacitor/browser';
import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';

export default {
    isSignedIn() {
        const store = useStore();
        if (accessToken.value) {
            return true;
        } else {
            return false;
        }
    },
    async writeToClipboard(value: string) {
        await Clipboard.write({
            string: value,
        });
    },
    async readFromClipboard() {
        const { type, value } = await Clipboard.read();
        alert(`Got ${type} from clipboard: ${value}`);
        return value;
    },

    async updatePushNotificationList() {
        const store = useStore();
        try {
            const pushNotifications = (await AppAPI.getPushNotifications(store.showArchived)).data;
            if (pushNotifications) {
                store.pushNotificationList = pushNotifications;
            }
        } catch (error) {
            console.log('Error getting push notifications:', error);
        }

        try {
            const user = (await AppAPI.getUser()).data;
            if (user) {
                store.user = user;
            }
        } catch (error) {
            console.log('Error getting user:', error);
        }
    },

    getSelectedPropertyData() {
        const store = useStore();
        const property = store.reservationData?.tech_reservations?.find(
            (x: any) => x.id === store.selectedTechReservationId
        );
        return property || {};
    },

    async getHostedForms() {
        const store = useStore();
        const response = await AppAPI.getHostedFormList(store.selectedReservationId);
        if (response.data) {
            store.hostedForms = response.data;
        }
    },

    capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },

    isNotifications() {
        const store = useStore();
        const value = false;

        if (store.pushNotificationList) {
            if (store.pushNotificationList.length > 0) {
                return true;
            }
        }

        return value;
    },
    async openLink(url: string) {
        await Browser.open({ url: url });
    },

    async checkPushNotificationPermission() {
        if (Capacitor.getPlatform() === 'web') {
            return true;
        }
        const isAllowed = await PushNotifications.checkPermissions();

        if (isAllowed.receive === 'granted') {
            return true;
        } else {
            return false;
        }
    },

    async refreshCurrentReservationData() {
        const store = useStore();
        const reservation = (await AppAPI.getReservationData(store.selectedReservationId)).data;
        store.reservationData = reservation;
        store.selectedTechReservationId = reservation.tech_reservations[0].id || '';
    },

    async refreshReservationList() {
        const store = useStore();
        const reservations = (await AppAPI.getAllReservations()).data;
        store.allReservations = reservations;
    },

    isImpersonating() {
        const store = useStore();
        if (store.user.impersonating_user) {
            return true;
        } else {
            return false;
        }
    },
};
