<template>
    <ion-card>
        <ion-card-header>
            <ion-label class="section-title">{{ isCheckIn ? 'Arrival' : isCheckOut ? 'Departure' : '' }}</ion-label>
        </ion-card-header>
        <ion-card-content>
            <ion-item :lines="isCheckIn ? 'full' : 'none'">
                <ion-label style="margin-top: 10px">{{
                    isCheckIn
                        ? formatDateSentance(AppFunctions.getSelectedPropertyData().check_in_dt)
                        : isCheckOut
                          ? formatDateSentance(AppFunctions.getSelectedPropertyData().check_out_dt)
                          : ''
                }}</ion-label>
            </ion-item>
            <template v-if="isCheckIn">
                <ion-item lines="full" v-if="selectedProperty.rental_property.directions">
                    <div style="margin: 10px 0">
                        <ion-label style="font-weight: bold; font-size: medium" position="stacked"
                            >Directions</ion-label
                        >
                        <ion-label style="white-space: pre-wrap; overflow-wrap: break-word">
                            {{ directionsText }}
                            <span
                                v-if="isShortDirections"
                                @click="isShortDirections = false"
                                style="text-decoration: underline; cursor: pointer"
                                >View More</span
                            >
                        </ion-label>
                    </div>
                </ion-item>

                <div v-if="selectedProperty.rental_property.check_in_options.category">
                    <ion-item lines="full">
                        <div style="margin: 10px 0">
                            <ion-label style="font-weight: bold; font-size: medium" position="stacked"
                                >Check In Option</ion-label
                            >
                            <ion-label style="white-space: pre-wrap; overflow-wrap: break-word">
                                {{ selectedProperty.rental_property.check_in_options.category }}
                            </ion-label>
                        </div>
                    </ion-item>
                    <ion-item lines="full" v-if="selectedProperty.rental_property.check_in_options.instruction">
                        <div style="margin: 10px 0">
                            <ion-label style="font-weight: bold; font-size: medium" position="stacked"
                                >Check In Instructions</ion-label
                            >
                            <ion-label style="white-space: pre-wrap; overflow-wrap: break-word">
                                {{ selectedProperty.rental_property.check_in_options.instruction }}
                            </ion-label>
                        </div>
                    </ion-item>
                </div>

                <ion-item lines="none" v-if="selectedProperty.rental_property.parking_info">
                    <div style="margin: 10px 0">
                        <ion-label style="font-weight: bold; font-size: medium" position="stacked"
                            >Parking Info</ion-label
                        >
                        <ion-label style="white-space: pre-wrap; overflow-wrap: break-word">
                            {{ selectedProperty.rental_property.parking_info || 'N/A' }}
                        </ion-label>
                    </div>
                </ion-item>
            </template>
        </ion-card-content>
    </ion-card>
</template>

<script lang="ts">
    import { defineComponent, computed, ref } from 'vue';
    import { useStore } from '@/store/store';
    import AppFunctions from '@/services/AppFunctions';
    import { Capacitor } from '@capacitor/core';
    import { formatDateSentance } from '@/services/Date';

    export default defineComponent({
        props: {
            isCheckIn: {
                type: Boolean,
                default: false,
            },
            isCheckOut: {
                type: Boolean,
                default: false,
            },
        },
        setup() {
            const store = useStore();

            const selectedProperty = computed(() => {
                return AppFunctions.getSelectedPropertyData();
            });

            const isShortDirections = ref(selectedProperty.value.rental_property.directions?.length > 200);
            const directionsText = computed(() => {
                if (selectedProperty.value.rental_property.directions?.length > 200 && isShortDirections.value) {
                    return selectedProperty.value.rental_property.directions?.substring(0, 200) + '...';
                } else {
                    return selectedProperty.value.rental_property.directions;
                }
            });

            return {
                selectedProperty,
                store,
                isShortDirections,
                directionsText,
                AppFunctions,
                Capacitor,
                formatDateSentance,
            };
        },
    });
</script>
