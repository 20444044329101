<template>
    <ion-menu mode="ios" side="start" content-id="main-content" v-if="accessToken">
        <ion-header class="ion-no-border" style="background-color: #f0f2f5">
            <ion-toolbar color="#F0F2F5">
                <ion-item lines="none" class="ldx-nav-header" style="width: 100%">
                    <ion-item
                        lines="none"
                        class="ldx-nav-header"
                        style="padding: 0px 10px; width: 100%; cursor: pointer"
                        @click="contactUsModal()"
                    >
                        <ion-avatar slot="start" v-if="store.reservationData.company?.logo_url">
                            <img
                                style="object-fit: contain"
                                :src="store.reservationData.company.logo_url"
                                @error="handleImageError"
                            />
                        </ion-avatar>
                        <ion-avatar slot="start" v-else>
                            <img style="object-fit: contain" :src="staylioLogo" />
                        </ion-avatar>
                        <ion-label>
                            <ion-list-header style="padding: 0px"
                                ><ion-label
                                    style="
                                        padding: 0px;
                                        font-size: clamp(0.9rem, 4.25vw - 1.5rem, 0.9rem);
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    "
                                    class="ion-no-margin"
                                >
                                    {{
                                        store.reservationData?.company?.title ||
                                        (!store.viewingChatWithoutReservation
                                            ? selectedProperty?.rental_property?.name
                                            : '')
                                    }}
                                </ion-label></ion-list-header
                            >
                            <ion-card-subtitle
                                v-if="store.reservationData?.company?.title && !store.viewingChatWithoutReservation"
                                style="
                                    font-size: clamp(0.5rem, 4.25vw - 1.5rem, 0.65rem);
                                    font-weight: bold;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                "
                                >{{ selectedProperty?.rental_property?.name }}</ion-card-subtitle
                            >
                        </ion-label>
                    </ion-item>
                </ion-item>
            </ion-toolbar>
        </ion-header>

        <ion-content style="--background: #f0f2f5">
            <ion-list style="padding: 0px">
                <ion-menu-toggle
                    :auto-hide="false"
                    v-for="(page, index) in appPages"
                    :key="index"
                    style="cursor: pointer"
                >
                    <ion-item
                        lines="none"
                        @click="transitionClear(page.url, index)"
                        style="padding: 0px 10px"
                        :style="[
                            isCurrentRouteActive(page.url)
                                ? 'background: rgba(var(--ion-color-secondary-rgb), 0.25)'
                                : '',
                        ]"
                        v-if="
                            (AppFunctions.isImpersonating() && page.hideOnImpersonation == false) ||
                            !AppFunctions.isImpersonating()
                        "
                    >
                        <ion-icon
                            slot="start"
                            :src="page.iconSolid"
                            :style="`color: var(${isCurrentRouteActive(page.url) ? page.selectedColor : page.color});`"
                        ></ion-icon>
                        <ion-label style="font-weight: 500">{{ page.title }}</ion-label>
                    </ion-item>
                </ion-menu-toggle>
            </ion-list>
        </ion-content>
    </ion-menu>
</template>

<script lang="ts">
    import { IonApp, IonRouterOutlet, useIonRouter } from '@ionic/vue';
    import { computed, defineComponent, ref, watch } from 'vue';
    import {
        calendarClear,
        calendarNumber,
        home,
        list,
        calendar,
        lockClosed,
        notifications,
        personCircle,
        chatbubbles,
        people,
        homeOutline,
        chatbubblesOutline,
        personCircleOutline,
        lockClosedOutline,
        documents,
        documentsOutline,
        peopleCircleOutline,
        peopleCircle,
        shareSocial,
        shareSocialOutline,
    } from 'ionicons/icons';
    import { useStore } from '@/store/store';
    import AppFunctions from '@/services/AppFunctions';
    import { Capacitor } from '@capacitor/core';
    import { accessToken } from '@/services/Authentication';
    import { logout } from '@/services/Authentication';
    import router from '@/router';
    import {
        conversationsModal,
        sharedUsersListModal,
        swapReservationModal,
        contactUsModal,
    } from '@/services/ModalController';

    export default defineComponent({
        name: 'SideMenu',
        components: {
            //
        },
        setup() {
            const store = useStore();
            const ionRouter = useIonRouter();
            const currentRoute = ref(router.currentRoute.value.path);
            const staylioLogo = ref('https://staylio.com/wp-content/uploads/2024/02/Staylio-Logo-Symbol-78x78.png');

            const selectedProperty = computed(() => {
                return AppFunctions.getSelectedPropertyData();
            });

            const handleImageError = (e: any) => {
                console.log('error', e);
                e.target.src = staylioLogo.value;
            };

            const appPages = computed(() => {
                const list = [
                    {
                        title: 'Home',
                        url: '/tabs/reservation',
                        color: '--ion-color-primary',
                        selectedColor: '--ion-color-primary',
                        iconSolid: home,
                        iconOutline: homeOutline,
                        hideOnImpersonation: false,
                    },
                    {
                        title: 'Chat',
                        url: '/tabs/messages',
                        color: '--ion-color-primary',
                        selectedColor: '--ion-color-primary',
                        iconSolid: chatbubbles,
                        iconOutline: chatbubblesOutline,
                        hideOnImpersonation: true,
                    },
                    {
                        title: 'View Other Stays',
                        url: '/changeReservation',
                        color: '--ion-color-primary',
                        selectedColor: '--ion-color-primary',
                        iconSolid: documents,
                        iconOutline: documentsOutline,
                        hideOnImpersonation: false,
                    },
                    {
                        title: 'Profile',
                        url: '/tabs/settings',
                        color: '--ion-color-primary',
                        selectedColor: '--ion-color-primary',
                        iconSolid: personCircle,
                        iconOutline: personCircleOutline,
                        hideOnImpersonation: false,
                    },
                    {
                        title: 'Sign Out',
                        url: '/signout',
                        color: '--ion-color-primary',
                        selectedColor: '--ion-color-primary',
                        iconSolid: lockClosed,
                        iconOutline: lockClosedOutline,
                        hideOnImpersonation: false,
                    },
                ];

                if (!store.viewingChatWithoutReservation && store.selectedReservationId) {
                    list.splice(3, 0, {
                        title: 'Shares',
                        url: '/sharedUsers',
                        color: '--ion-color-primary',
                        selectedColor: '--ion-color-primary',
                        iconSolid: shareSocial,
                        iconOutline: shareSocialOutline,
                        hideOnImpersonation: true,
                    });
                }

                return list;
            });

            const getPlatform = () => {
                return Capacitor.getPlatform();
            };

            const openMessages = async () => {
                if (router.currentRoute.value.path.includes('/tabs/messages/conversation/')) {
                    //open modal of chat list here
                    await conversationsModal();
                    //store.scrollToBottomCounter++;
                } else {
                    store.scrollToBottomCounter = 0;
                    let url = '';
                    if (store.viewingChatWithoutReservation) {
                        url = '/tabs/messages/conversation/' + store.noReservationChatConversationId + '/';
                    } else {
                        const conversation = '/conversation/' + store.reservationData.conversation_id;
                        url = '/tabs/messages' + (store.reservationData.conversation_id ? conversation : '/');
                    }
                    ionRouter.replace(url);
                }
            };

            const transitionClear = async (page: string, index: number) => {
                if (page == '/signout') {
                    await logout();
                } else if (page == '/tabs/messages') {
                    openMessages();
                } else if (page == '/changeReservation') {
                    await swapReservationModal();
                    ionRouter.replace('/tabs/reservation');
                } else if (page == '/sharedUsers') {
                    await sharedUsersListModal();
                    ionRouter.replace('/tabs/reservation');
                } else {
                    ionRouter.replace(page);
                }
            };

            const isCurrentRouteActive = (page: string) => {
                return currentRoute.value.includes(page);
            };

            watch(
                () => router.currentRoute.value.path,
                (newValue, oldValue) => {
                    currentRoute.value = newValue;
                }
            );

            return {
                ionRouter,
                home,
                calendarNumber,
                calendarClear,
                list,
                calendar,
                appPages,
                personCircle,
                chatbubbles,
                people,
                store,
                accessToken,
                selectedProperty,
                currentRoute,
                staylioLogo,
                AppFunctions,
                contactUsModal,
                isCurrentRouteActive,
                getPlatform,
                transitionClear,
                handleImageError,
            };
        },
    });
</script>

<style scoped>
    .ldx-menu-list {
        padding-top: 0;
    }

    .ldx-nav-header {
        --background: #f0f2f5;
    }

    .ldx-nav-header ion-avatar,
    .ldx-nav-header img {
        --background: #ffffff;
    }

    .ldx-nav-header ion-list-header {
        padding-left: 0;
        padding: 0px;
        line-height: 1;
        font-size: 16px;
        min-height: 20px;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
    }

    .ldx-nav-header ion-card-subtitle {
        font-size: 14px;
    }

    ion-menu ion-content {
        --background: var(--ion-item-background, var(--ion-background-color, #fff));
    }

    ion-menu.md ion-list {
        padding: 10px 8px;
    }

    ion-menu.md ion-note {
        margin-bottom: 30px;
    }

    ion-menu.md ion-list#inbox-list {
        border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
    }

    ion-menu.md ion-list#inbox-list ion-list-header {
        font-size: 22px;
        font-weight: 600;
        min-height: 20px;
    }

    ion-menu.md ion-list#labels-list ion-list-header {
        font-size: 16px;
        margin-bottom: 18px;
        color: #757575;
        min-height: 26px;
    }

    ion-menu.md ion-item {
        --padding-start: 10px;
        --padding-end: 10px;
        border-radius: 4px;
    }

    ion-menu.md ion-item.selected {
        --background: rgba(var(--ion-color-primary-rgb), 0.14);
    }

    ion-menu.md ion-item.selected ion-icon {
        color: var(--ion-color-primary);
    }

    ion-menu.md ion-item ion-icon {
        color: #616e7e;
    }

    ion-menu.md ion-item ion-label {
        font-weight: 500;
    }

    ion-menu.ios ion-content {
        --padding-bottom: 20px;
    }

    ion-menu.ios ion-list {
        padding: 20px 0 0 0;
    }

    ion-menu.ios ion-note {
        line-height: 24px;
        margin-bottom: 20px;
    }

    ion-menu.ios ion-item {
        --padding-start: 16px;
        --padding-end: 16px;
        --min-height: 50px;
    }

    ion-menu.ios ion-item.selected ion-icon {
        color: var(--ion-color-primary);
    }

    ion-menu.ios ion-item ion-icon {
        font-size: 24px;
        color: #73849a;
    }

    ion-menu.ios ion-list#labels-list ion-list-header {
        margin-bottom: 8px;
    }

    ion-menu.ios ion-list-header,
    ion-menu.ios ion-note {
        padding-left: 16px;
        padding-right: 16px;
    }

    ion-menu.ios ion-note {
        margin-bottom: 8px;
    }

    ion-note {
        display: inline-block;
        font-size: 16px;

        color: var(--ion-color-medium-shade);
    }

    ion-avatar {
        --border-radius: 4px;
        background: #fff;
    }

    ion-item::part(native) {
        padding: 0px;
    }
</style>
