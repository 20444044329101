import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_CheckInOutCard = _resolveComponent("CheckInOutCard")!
  const _component_PropertyAddressDirections = _resolveComponent("PropertyAddressDirections")!
  const _component_WifiDetails = _resolveComponent("WifiDetails")!
  const _component_DoorCode = _resolveComponent("DoorCode")!
  const _component_HouseManual = _resolveComponent("HouseManual")!
  const _component_PropertyCustomVariables = _resolveComponent("PropertyCustomVariables")!
  const _component_CheckOutTasks = _resolveComponent("CheckOutTasks")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, {
    title: _ctx.title,
    showCloseButton: false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_card_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { class: "section-title" }, {
                default: _withCtx(() => [
                  _createTextVNode("Device Offline")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_card_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, { lines: "none" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" You are currently offline. Please check your internet connection. ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.getPlatform() != 'web')
                ? (_openBlock(), _createBlock(_component_ion_button, {
                    key: 0,
                    style: {"justify-content":"center","display":"flex","align-items":"center","padding":"0 10px 10px"},
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openDeviceSettings()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Open Device Settings ")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.store.selectedReservationId)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_ion_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, { class: "section-title" }, {
                      default: _withCtx(() => [
                        _createTextVNode("Viewing Reservation in Offline Mode")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_card_content, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_list, { lines: "full" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(" When using Staylio in offline mode, you'll see the last viewed reservation. Please note that the information may be outdated, as it reflects the details from the last time the app was connected to the internet. ")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { slot: "start" }, {
                              default: _withCtx(() => [
                                _createTextVNode("Property Name")
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_label, { slot: "end" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.selectedProperty.rental_property.name), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { slot: "start" }, {
                              default: _withCtx(() => [
                                _createTextVNode("Invoice #")
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_label, { slot: "end" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.store.selectedReservationId), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_CheckInOutCard, { isCheckIn: "" }),
            _createVNode(_component_PropertyAddressDirections, {
              hideMap: "",
              showCopy: ""
            }),
            _createVNode(_component_WifiDetails),
            _createVNode(_component_DoorCode),
            _createVNode(_component_HouseManual),
            _createVNode(_component_PropertyCustomVariables, { location: 1 }),
            _createVNode(_component_PropertyCustomVariables, { location: 2 }),
            _createVNode(_component_PropertyCustomVariables, { location: 3 }),
            _createVNode(_component_CheckInOutCard, { isCheckOut: "" }),
            _createVNode(_component_CheckOutTasks),
            _createVNode(_component_ion_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, { class: "section-title" }, {
                      default: _withCtx(() => [
                        _createTextVNode("Company Details")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_card_content, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_list, { lines: "full" }, {
                      default: _withCtx(() => [
                        (_ctx.store.reservationData.company.title)
                          ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, { slot: "start" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Company Name")
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_label, { slot: "end" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.store.reservationData.company.title), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.store.reservationData.company.public_phone)
                          ? (_openBlock(), _createBlock(_component_ion_item, { key: 1 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, { slot: "start" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Phone Contact")
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_label, { slot: "end" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.store.reservationData.company.public_phone), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.store.reservationData.company.public_email)
                          ? (_openBlock(), _createBlock(_component_ion_item, { key: 2 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, { slot: "start" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Email Contact")
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_label, { slot: "end" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.store.reservationData.company.public_email), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.store.reservationData.company.public_website)
                          ? (_openBlock(), _createBlock(_component_ion_item, {
                              key: 3,
                              lines: "none"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, { slot: "start" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Company Website")
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_label, { slot: "end" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.store.reservationData.company.public_website), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title"]))
}