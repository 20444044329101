<template>
    <ion-page>
        <ion-tabs>
            <ion-router-outlet></ion-router-outlet>
            <ion-tab-bar id="footer" slot="bottom" v-if="accessToken">
                <ion-tab-button tab="messages" @click="openMessages()" v-if="!AppFunctions.isImpersonating()">
                    <ion-icon
                        aria-hidden="true"
                        :icon="
                            router.currentRoute.value.path.includes('/tabs/messages')
                                ? chatbubbleEllipses
                                : chatbubbleEllipsesOutline
                        "
                        :color="router.currentRoute.value.path.includes('/tabs/messages') ? 'primary' : 'light'"
                    />
                    <ion-label class="footer-label">Chat</ion-label>
                </ion-tab-button>
                <ion-tab-button tab="reservation" @click="swapReservation()">
                    <ion-icon
                        aria-hidden="true"
                        :icon="router.currentRoute.value.path.includes('/tabs/reservation') ? home : homeOutline"
                        :color="router.currentRoute.value.path.includes('/tabs/reservation') ? 'primary' : 'light'"
                    />
                    <ion-label class="footer-label">Stays</ion-label>
                </ion-tab-button>
                <ion-tab-button tab="settings" @click="openSettings()">
                    <ion-icon
                        aria-hidden="true"
                        :icon="
                            router.currentRoute.value.path.includes('/tabs/settings')
                                ? personCircle
                                : personCircleOutline
                        "
                        :color="router.currentRoute.value.path.includes('/tabs/settings') ? 'primary' : 'light'"
                    />
                    <ion-label class="footer-label">Profile</ion-label>
                </ion-tab-button>
            </ion-tab-bar>
        </ion-tabs>
    </ion-page>
</template>

<script lang="ts">
    import { useIonRouter } from '@ionic/vue';
    import { defineComponent } from 'vue';
    import { useStore } from '@/store/store';
    import {
        chatbubbleEllipses,
        chatbubbleEllipsesOutline,
        home,
        homeOutline,
        personCircleOutline,
        personCircle,
        settings,
        settingsOutline,
        refreshCircle,
    } from 'ionicons/icons';
    import router from '@/router';
    import { accessToken } from '@/services/Authentication';
    import { swapReservationModal, conversationsModal } from '@/services/ModalController';
    import AppFunctions from '@/services/AppFunctions';

    export default defineComponent({
        components: {},
        setup() {
            const ionRouter = useIonRouter();
            const store = useStore();

            const swapReservation = async () => {
                if (router.currentRoute.value.path.includes('/tabs/reservation')) {
                    await swapReservationModal();
                } else {
                    ionRouter.replace('/tabs/reservation');
                }
            };

            const openMessages = async () => {
                if (router.currentRoute.value.path.includes('/tabs/messages/conversation/')) {
                    await conversationsModal();
                } else {
                    store.scrollToBottomCounter = 0;
                    let url = '';
                    if (store.viewingChatWithoutReservation) {
                        url = '/tabs/messages/conversation/' + store.noReservationChatConversationId + '/';
                    } else {
                        const conversation = '/conversation/' + store.reservationData.conversation_id;
                        url = '/tabs/messages' + (store.reservationData.conversation_id ? conversation : '/');
                    }
                    ionRouter.replace(url);
                }
            };

            const openSettings = () => {
                if (!router.currentRoute.value.path.includes('/tabs/settings')) {
                    ionRouter.replace('/tabs/settings');
                }
            };

            return {
                ionRouter,
                router,
                store,
                home,
                homeOutline,
                chatbubbleEllipsesOutline,
                personCircleOutline,
                settings,
                settingsOutline,
                chatbubbleEllipses,
                personCircle,
                refreshCircle,
                accessToken,
                AppFunctions,
                openMessages,
                swapReservation,
                openSettings,
            };
        },
    });
</script>

<style scoped>
    ion-label {
        font-weight: 500;
        font-size: 12px;
        font-family: 'San Francisco', sans-serif;
        overflow: visible;
    }
    ion-icon {
        margin: 0px;
    }
</style>
