import { modalController } from '@ionic/vue';
import PropertyDetailsModal from '@/components/Modals/PropertyDetailsModal.vue';
import PaymentModal from '@/components/Modals/PaymentModal.vue';
import GuestReviewFormModal from '@/components/Modals/GuestReviewFormModal.vue';
import PoliciesModal from '@/components/Modals/PoliciesModal.vue';
import DuringStayModal from '@/components/Modals/DuringStayModal.vue';
import NotificationDetailsModal from '@/components/Modals/NotificationDetailsModal.vue';
import ArrivalModal from '@/components/Modals/ArrivalModal.vue';
import DepartureModal from '@/components/Modals/DepartureModal.vue';
import SwapReservationModal from '@/components/Modals/SwapReservationModal.vue';
import { logEvent } from './FirebaseAnalytics';
import DuringStayDetailModal from '@/components/Modals/DuringStayDetailModal.vue';
import DurignStayListModal from '@/components/Modals/DuringStayListModal.vue';
import AppAPI from '@/services/AppAPI';
import { useStore } from '@/store/store';
import HostedFormsListModal from '@/components/Modals/HostedFormsListModal.vue';
import HostedFormsSurveyModal from '@/components/Modals/HostedFormsSurveyModal.vue';
import NotificationListModal from '@/components/Modals/NotificationListModal.vue';
import ContactUsModal from '@/components/Modals/ContactUsModal.vue';
import GuestReviewsModal from '@/components/Modals/GuestReviewsModal.vue';
import RentalAgreementModal from '@/components/Modals/RentalAgreementModal.vue';
import AmenitiesModal from '@/components/Modals/AmenitiesModal.vue';
import PushNotificationReminderModal from '@/components/Modals/PushNotificationReminderModal.vue';
import AppFunctions from './AppFunctions';
import GuestVerifyModal from '@/components/Modals/GuestVerifyModal.vue';
import ConversationsModal from '@/components/Modals/ConversationsModal.vue';
import LoginAboutModal from '@/components/Modals/LoginAboutModal.vue';
import SharedUsersListModal from '@/components/Modals/SharedUsersListModal.vue';
import VirtualTourModal from '@/components/Modals/VirtualTourModal.vue';
import OfflineModal from '@/components/Modals/OfflineModal.vue';

export const propertyDetailsModal = async () => {
    await logEvent('property_details_modal_opened');
    const modal = await modalController.create({
        component: PropertyDetailsModal,
        componentProps: {
            //
        },
    });

    await modal.present();

    const { data, role } = await modal.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};

export const arrivalModal = async () => {
    await logEvent('arrival_modal_opened');
    const modal = await modalController.create({
        component: ArrivalModal,
        componentProps: {
            //
        },
    });

    await modal.present();

    const { data, role } = await modal.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};

export const departureModal = async () => {
    await logEvent('departure_modal_opened');
    const modal = await modalController.create({
        component: DepartureModal,
        componentProps: {
            //
        },
    });

    await modal.present();

    const { data, role } = await modal.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};

export const paymentModal = async () => {
    await logEvent('payment_modal_opened');
    const modal = await modalController.create({
        component: PaymentModal,
        componentProps: {
            //
        },
    });

    await modal.present();

    const { data, role } = await modal.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};

export const guestReviewFormModal = async () => {
    const store = useStore();
    await logEvent('guest_review_modal_opened');
    const form = await AppAPI.getHostedFormGuestReview(store.selectedReservationId);
    const modal = await modalController.create({
        component: GuestReviewFormModal,
        componentProps: {
            data: form.data,
        },
    });

    await modal.present();

    const { data, role } = await modal.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};

export const guestReviewsModal = async () => {
    await logEvent('guest_review_modal_opened');
    const modal = await modalController.create({
        component: GuestReviewsModal,
        componentProps: {
            //
        },
    });

    await modal.present();

    const { data, role } = await modal.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};

export const policiesModal = async () => {
    await logEvent('policies_modal_opened');
    const modal = await modalController.create({
        component: PoliciesModal,
        componentProps: {
            //
        },
    });

    await modal.present();

    const { data, role } = await modal.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};

export const duringStayModal = async () => {
    await logEvent('during_stay_modal_opened');
    const modal = await modalController.create({
        component: DuringStayModal,
        componentProps: {
            //
        },
    });

    await modal.present();

    const { data, role } = await modal.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};

export const notificationDetailsModal = async (item: any) => {
    await logEvent('notification_details_modal_opened');
    const modal = await modalController.create({
        component: NotificationDetailsModal,
        componentProps: {
            item: item,
        },
    });

    await modal.present();

    const { data, role } = await modal.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};

export const swapReservationModal = async (noRefresh = false) => {
    await logEvent('swap_reservation_modal_opened');
    const modal = await modalController.create({
        component: SwapReservationModal,
        componentProps: {
            noRefresh: noRefresh,
        },
    });

    await modal.present();

    const { data, role } = await modal.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};

export const duringStayDetailModal = async (item: any) => {
    const modal = await modalController.create({
        component: DuringStayDetailModal,
        componentProps: {
            item: item,
        },
    });

    await modal.present();

    const { data, role } = await modal.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};

export const duringStayListModal = async (category: any) => {
    const modal = await modalController.create({
        component: DurignStayListModal,
        componentProps: {
            category: category,
        },
    });

    await modal.present();

    const { data, role } = await modal.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};

export const hostedFormsListModal = async () => {
    const modal = await modalController.create({
        component: HostedFormsListModal,
        componentProps: {
            //
        },
    });

    await modal.present();

    const { data, role } = await modal.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};

export const hostedFormsSurveyModal = async (hostedForm: any) => {
    const modal = await modalController.create({
        component: HostedFormsSurveyModal,
        componentProps: {
            hostedForm: hostedForm,
        },
    });

    await modal.present();

    const { data, role } = await modal.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};

export const notificationListModal = async (id = '') => {
    const modal = await modalController.create({
        component: NotificationListModal,
        componentProps: {
            id: id,
        },
    });

    await modal.present();

    const { data, role } = await modal.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};

export const contactUsModal = async () => {
    const modal = await modalController.create({
        component: ContactUsModal,
        componentProps: {
            //
        },
    });

    await modal.present();

    const { data, role } = await modal.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};

export const rentalAgreementModal = async () => {
    const modal = await modalController.create({
        component: RentalAgreementModal,
        componentProps: {
            //
        },
    });

    await modal.present();

    const { data, role } = await modal.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};

export const amenitiesModal = async (items: any) => {
    const modal = await modalController.create({
        component: AmenitiesModal,
        componentProps: {
            items: items,
        },
    });

    await modal.present();

    const { data, role } = await modal.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};

export const pushNotificationReminderModal = async () => {
    const modal = await modalController.create({
        component: PushNotificationReminderModal,
        componentProps: {
            //
        },
    });

    await modal.present();

    const { data, role } = await modal.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};

export const guestVerifyModal = async () => {
    const modal = await modalController.create({
        component: GuestVerifyModal,
        componentProps: {
            //
        },
    });

    await modal.present();

    const { data, role } = await modal.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};

export const conversationsModal = async () => {
    const modal = await modalController.create({
        component: ConversationsModal,
        componentProps: {
            //
        },
    });

    await modal.present();

    const { data, role } = await modal.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};

export const loginAboutModal = async () => {
    const modal = await modalController.create({
        component: LoginAboutModal,
        componentProps: {
            //
        },
    });

    await modal.present();

    const { data, role } = await modal.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};

export const sharedUsersListModal = async () => {
    const modal = await modalController.create({
        component: SharedUsersListModal,
        componentProps: {
            //
        },
    });

    await modal.present();

    const { data, role } = await modal.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};

export const virtualTourModal = async () => {
    const modal = await modalController.create({
        component: VirtualTourModal,
        componentProps: {
            //
        },
    });

    await modal.present();

    const { data, role } = await modal.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};

export const offlineModal = async () => {
    const modal = await modalController.create({
        component: OfflineModal,
        componentProps: {
            //
        },
    });

    await modal.present();

    const { data, role } = await modal.onDidDismiss();
    const response = {
        data: data,
        role: role,
    };
    return response;
};
