import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { style: {"margin":"10px 0"} }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { style: {"margin":"10px 0"} }
const _hoisted_4 = { style: {"margin":"10px 0"} }
const _hoisted_5 = { style: {"margin":"10px 0"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, { class: "section-title" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.isCheckIn ? 'Arrival' : _ctx.isCheckOut ? 'Departure' : ''), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_card_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_item, {
            lines: _ctx.isCheckIn ? 'full' : 'none'
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { style: {"margin-top":"10px"} }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.isCheckIn
                        ? _ctx.formatDateSentance(_ctx.AppFunctions.getSelectedPropertyData().check_in_dt)
                        : _ctx.isCheckOut
                          ? _ctx.formatDateSentance(_ctx.AppFunctions.getSelectedPropertyData().check_out_dt)
                          : ''), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["lines"]),
          (_ctx.isCheckIn)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.selectedProperty.rental_property.directions)
                  ? (_openBlock(), _createBlock(_component_ion_item, {
                      key: 0,
                      lines: "full"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                          _createVNode(_component_ion_label, {
                            style: {"font-weight":"bold","font-size":"medium"},
                            position: "stacked"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Directions")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_label, { style: {"white-space":"pre-wrap","overflow-wrap":"break-word"} }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.directionsText) + " ", 1),
                              (_ctx.isShortDirections)
                                ? (_openBlock(), _createElementBlock("span", {
                                    key: 0,
                                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isShortDirections = false)),
                                    style: {"text-decoration":"underline","cursor":"pointer"}
                                  }, "View More"))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.selectedProperty.rental_property.check_in_options.category)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_ion_item, { lines: "full" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_3, [
                            _createVNode(_component_ion_label, {
                              style: {"font-weight":"bold","font-size":"medium"},
                              position: "stacked"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("Check In Option")
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_label, { style: {"white-space":"pre-wrap","overflow-wrap":"break-word"} }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.selectedProperty.rental_property.check_in_options.category), 1)
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _: 1
                      }),
                      (_ctx.selectedProperty.rental_property.check_in_options.instruction)
                        ? (_openBlock(), _createBlock(_component_ion_item, {
                            key: 0,
                            lines: "full"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_4, [
                                _createVNode(_component_ion_label, {
                                  style: {"font-weight":"bold","font-size":"medium"},
                                  position: "stacked"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Check In Instructions")
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_label, { style: {"white-space":"pre-wrap","overflow-wrap":"break-word"} }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.selectedProperty.rental_property.check_in_options.instruction), 1)
                                  ]),
                                  _: 1
                                })
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.selectedProperty.rental_property.parking_info)
                  ? (_openBlock(), _createBlock(_component_ion_item, {
                      key: 2,
                      lines: "none"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, [
                          _createVNode(_component_ion_label, {
                            style: {"font-weight":"bold","font-size":"medium"},
                            position: "stacked"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Parking Info")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_label, { style: {"white-space":"pre-wrap","overflow-wrap":"break-word"} }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.selectedProperty.rental_property.parking_info || 'N/A'), 1)
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}