import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ab2aec2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"display":"flex","justify-content":"space-evenly","align-items":"center","margin":"30px 15px 15px"} }
const _hoisted_2 = { style: {"display":"flex","flex-direction":"column"} }
const _hoisted_3 = {
  class: "progress-bar",
  style: {"width":"120px"}
}
const _hoisted_4 = { style: {"margin":"0px 30px 30px","justify-content":"center","display":"flex","flex-direction":"column"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, {
    showPropertySegment: "",
    title: "Guest Reviews"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_card_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { class: "section-title" }, {
                default: _withCtx(() => [
                  _createTextVNode("Review Your Stay")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_card_content, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_ion_label, {
                    class: "bold",
                    style: {"font-size":"35px"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.currentReviewAverageRating), 1)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", null, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                        (i <= _ctx.currentReviewAverageRating)
                          ? (_openBlock(), _createBlock(_component_ion_icon, {
                              key: 0,
                              icon: _ctx.star,
                              style: {"color":"#ffd700","width":"20px","height":"20px"}
                            }, null, 8, ["icon"]))
                          : (_openBlock(), _createBlock(_component_ion_icon, {
                              key: 1,
                              icon: _ctx.starOutline,
                              style: {"color":"black","width":"20px","height":"20px"}
                            }, null, 8, ["icon"]))
                      ], 64))
                    }), 64))
                  ]),
                  _createVNode(_component_ion_label, { class: "bold" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.currentReview.reviews?.total_count) + " reviews", 1)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reversedArray, (i) => {
                    return (_openBlock(), _createElementBlock("div", {
                      style: {"display":"flex","align-items":"center"},
                      key: i
                    }, [
                      _createVNode(_component_ion_label, {
                        class: "bold",
                        style: {"font-size":"20px"}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(i), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", {
                          class: "progress",
                          style: _normalizeStyle([`width:${_ctx.calPercentage[i - 1]}%`])
                        }, null, 4)
                      ]),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.calPercentage[i - 1]) + "%", 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]))
                  }), 128))
                ])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Your feedback is important! Please share what you loved about your stay and any suggestions for improvement.")
                  ]),
                  _: 1
                }),
                (_ctx.store.reservationData?.guest_review_status == 'TODO')
                  ? (_openBlock(), _createBlock(_component_ion_button, {
                      key: 0,
                      style: {"width":"100%","margin-top":"15px","--border-radius":"5px","font-weight":"bold"},
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openGuestReviewFormModal())),
                      disabled: !_ctx.allowGuestReview
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Leave Review")
                      ]),
                      _: 1
                    }, 8, ["disabled"]))
                  : _createCommentVNode("", true),
                (!_ctx.allowGuestReview && _ctx.store.reservationData?.guest_review_status == 'TODO')
                  ? (_openBlock(), _createBlock(_component_ion_label, {
                      key: 1,
                      style: {"font-size":"13px","font-weight":"lighter"}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Reviews can only be submitted after the reservation has ended. ")
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.store.reservationData?.guest_review_status != 'TODO')
                  ? (_openBlock(), _createBlock(_component_ion_button, {
                      key: 2,
                      style: {"width":"100%","margin-top":"15px","--border-radius":"5px","font-weight":"bold"},
                      disabled: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          icon: _ctx.checkmarkCircle,
                          style: {"margin-right":"15px"}
                        }, null, 8, ["icon"]),
                        _createTextVNode("Review Submitted")
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}