<template>
    <modal-base-layout showPropertySegment title="Guest Reviews">
        <ion-card>
            <ion-card-header>
                <ion-label class="section-title">Review Your Stay</ion-label>
            </ion-card-header>
            <ion-card-content>
                <div style="display: flex; justify-content: space-evenly; align-items: center; margin: 30px 15px 15px">
                    <div style="display: flex; flex-direction: column">
                        <ion-label class="bold" style="font-size: 35px"> {{ currentReviewAverageRating }} </ion-label>
                        <div>
                            <template v-for="i in 5" :key="i">
                                <ion-icon
                                    v-if="i <= currentReviewAverageRating"
                                    :icon="star"
                                    style="color: #ffd700; width: 20px; height: 20px"
                                ></ion-icon>
                                <ion-icon
                                    v-else
                                    :icon="starOutline"
                                    style="color: black; width: 20px; height: 20px"
                                ></ion-icon>
                            </template>
                        </div>
                        <ion-label class="bold">{{ currentReview.reviews?.total_count }} reviews</ion-label>
                    </div>
                    <div>
                        <div style="display: flex; align-items: center" v-for="i in reversedArray" :key="i">
                            <ion-label class="bold" style="font-size: 20px">{{ i }}</ion-label>
                            <div class="progress-bar" style="width: 120px">
                                <div class="progress" :style="[`width:${calPercentage[i - 1]}%`]"></div>
                            </div>
                            <ion-label>{{ calPercentage[i - 1] }}%</ion-label>
                        </div>
                    </div>
                </div>
                <div style="margin: 0px 30px 30px; justify-content: center; display: flex; flex-direction: column">
                    <ion-label
                        >Your feedback is important! Please share what you loved about your stay and any suggestions for
                        improvement.</ion-label
                    >
                    <ion-button
                        v-if="store.reservationData?.guest_review_status == 'TODO'"
                        style="width: 100%; margin-top: 15px; --border-radius: 5px; font-weight: bold"
                        @click="openGuestReviewFormModal()"
                        :disabled="!allowGuestReview"
                        >Leave Review</ion-button
                    >
                    <ion-label
                        v-if="!allowGuestReview && store.reservationData?.guest_review_status == 'TODO'"
                        style="font-size: 13px; font-weight: lighter"
                    >
                        Reviews can only be submitted after the reservation has ended.
                    </ion-label>
                    <ion-button
                        v-if="store.reservationData?.guest_review_status != 'TODO'"
                        style="width: 100%; margin-top: 15px; --border-radius: 5px; font-weight: bold"
                        disabled
                        ><ion-icon :icon="checkmarkCircle" style="margin-right: 15px"></ion-icon>Review
                        Submitted</ion-button
                    >
                </div>
            </ion-card-content>
        </ion-card>
    </modal-base-layout>
</template>

<script lang="ts">
    import { defineComponent, computed, onMounted, ref } from 'vue';
    import { useStore } from '@/store/store';
    import AppFunctions from '@/services/AppFunctions';
    import { guestReviewFormModal } from '@/services/ModalController';
    import { checkmarkCircle } from 'ionicons/icons';
    import AppAPI from '@/services/AppAPI';
    import { star, starOutline } from 'ionicons/icons';
    import { calculateDaysDifferenceFromToday } from '@/services/Date';

    export default defineComponent({
        setup() {
            const store = useStore();
            const reviews = ref([]);

            const allowGuestReview = computed(() => {
                return calculateDaysDifferenceFromToday(store.reservationData?.to_date) <= 0;
            });

            const currentReview = computed(() => {
                return (
                    reviews.value.find((review: any) => review.id === selectedProperty.value.rental_property.id) ||
                    ({} as any)
                );
            });

            const currentReviewAverageRating = computed(() => {
                if (currentReview.value?.reviews?.total_count === 0) {
                    return 0;
                }
                let calAverage =
                    (currentReview.value?.reviews?.one_star_count * 1 +
                        currentReview.value?.reviews?.two_star_count * 2 +
                        currentReview.value?.reviews?.three_star_count * 3 +
                        currentReview.value?.reviews?.four_star_count * 4 +
                        currentReview.value?.reviews?.five_star_count * 5) /
                    currentReview.value?.reviews?.total_count;
                calAverage = Math.round(calAverage * 10) / 10;
                return calAverage;
            });

            const calPercentage = computed(() => {
                const totalReviews = currentReview.value?.reviews?.total_count;
                const oneStar = Math.round((currentReview.value?.reviews?.one_star_count / totalReviews) * 100) || 0;
                const twoStar = Math.round((currentReview.value?.reviews?.two_star_count / totalReviews) * 100) || 0;
                const threeStar =
                    Math.round((currentReview.value?.reviews?.three_star_count / totalReviews) * 100) || 0;
                const fourStar = Math.round((currentReview.value?.reviews?.four_star_count / totalReviews) * 100) || 0;
                const fiveStar = Math.round((currentReview.value?.reviews?.five_star_count / totalReviews) * 100) || 0;
                return [oneStar, twoStar, threeStar, fourStar, fiveStar];
            });

            const selectedProperty = computed(() => {
                return AppFunctions.getSelectedPropertyData();
            });

            const reversedArray = computed(() => {
                return Array.from({ length: 5 }, (v, i) => i + 1).reverse();
            });

            onMounted(async () => {
                const data = await AppAPI.getPastReviews();
                reviews.value = data.data;
            });

            const openGuestReviewFormModal = async () => {
                await guestReviewFormModal();
                const data = await AppAPI.getPastReviews();
                reviews.value = data.data;
            };

            return {
                selectedProperty,
                store,
                checkmarkCircle,
                reviews,
                star,
                reversedArray,
                currentReview,
                starOutline,
                currentReviewAverageRating,
                calPercentage,
                allowGuestReview,
                openGuestReviewFormModal,
            };
        },
    });
</script>

<style scoped>
    .bold {
        font-weight: bold;
    }

    .progress-bar {
        width: 100%;
        background-color: #f3f3f3;
        border-radius: 50px;
        margin: 5px 10px;
        height: 12px;
    }

    .progress {
        background-color: var(--ion-color-primary);
        border-radius: 50px;
        height: 12px;
    }
</style>
