<template>
    <div
        v-if="
            selectedProperty.rental_property.wifi_details.name && selectedProperty.rental_property.wifi_details.password
        "
    >
        <ion-card>
            <ion-card-header>
                <ion-label class="section-title">Wi-Fi Details</ion-label>
            </ion-card-header>
            <ion-card-content>
                <ion-list lines="full">
                    <ion-item>
                        <ion-input
                            readonly
                            :value="selectedProperty.rental_property.wifi_details.name"
                            label="Wi-Fi Name (SSID)"
                            label-placement="stacked"
                        ></ion-input>
                    </ion-item>
                    <ion-item :lines="Capacitor.getPlatform() != 'web' ? 'full' : 'none'">
                        <ion-input
                            readonly
                            :value="selectedProperty.rental_property.wifi_details.password"
                            label="Password"
                            label-placement="stacked"
                        ></ion-input>
                        <ion-icon
                            slot="end"
                            :icon="copyOutline"
                            @click="copyWifi(selectedProperty.rental_property.wifi_details.password)"
                        ></ion-icon>
                    </ion-item>

                    <ion-item lines="none" v-if="Capacitor.getPlatform() != 'web'">
                        <ion-label
                            style="text-align: center; align-items: center; display: flex; justify-content: center"
                            @click="
                                joinNetwork(
                                    selectedProperty.rental_property.wifi_details.name,
                                    selectedProperty.rental_property.wifi_details.password
                                )
                            "
                            ><ion-icon :src="wifi" style="padding-right: 10px" />Connect to Wi-Fi</ion-label
                        >
                    </ion-item>
                </ion-list>
            </ion-card-content>
        </ion-card>
    </div>
    <div v-else>
        <ion-card>
            <ion-card-header>
                <ion-label class="section-title">Wi-Fi Details</ion-label>
            </ion-card-header>
            <ion-card-content>
                <ion-item lines="none">
                    <ion-label style="margin: 10px 0px">No Wi-Fi details provided</ion-label>
                </ion-item>
            </ion-card-content>
        </ion-card>
    </div>
</template>

<script lang="ts">
    import { defineComponent, computed } from 'vue';
    import { useStore } from '@/store/store';
    import AppFunctions from '@/services/AppFunctions';
    import { Capacitor } from '@capacitor/core';
    import WifiService from '@/services/WifiService';
    import { infoToast } from '@/services/ToastController';
    import { showLoading, hideLoading } from '@/services/LoadingController';
    import { wifi, copyOutline } from 'ionicons/icons';

    export default defineComponent({
        setup() {
            const store = useStore();

            const selectedProperty = computed(() => {
                return AppFunctions.getSelectedPropertyData();
            });

            const copyWifi = async (wifiPasscode: string) => {
                await AppFunctions.writeToClipboard(wifiPasscode);
                infoToast('Wifi Password Copied');
            };

            const joinNetwork = async (ssid: string, password: string) => {
                const loading = await showLoading('Joining Network');
                let result = false;
                try {
                    result = await WifiService.connectToWiFi(ssid, password);
                    hideLoading(loading);
                } catch (e) {
                    console.log(JSON.stringify(e));
                    hideLoading(loading);
                }

                if (Capacitor.getPlatform() == 'android') {
                    if (result) {
                        alert(
                            `Successfully added ${ssid} to device. If device is currently connected to another wifi network then it will not connect to ${ssid}. You will need to disconnect from the existing network and the device will automatically connect to ${ssid}.`
                        );
                    } else {
                        alert(`Failed to add ${ssid}. Please manually add the network.`);
                    }
                } else if (Capacitor.getPlatform() == 'ios') {
                    if (result) {
                        alert(`Successfully joined ${ssid}`);
                    }
                } else {
                    alert(`Option not available for ${Capacitor.getPlatform()}.`);
                }
            };

            return {
                selectedProperty,
                store,
                Capacitor,
                wifi,
                copyOutline,
                copyWifi,
                joinNetwork,
            };
        },
    });
</script>
