<template>
    <modal-base-layout title="Conversations">
        <ion-card
            style="cursor: pointer; margin: 15px"
            v-for="conversation in list"
            :key="conversation.id"
            @click="selectChat(conversation)"
        >
            <ion-card-content style="margin: 10px; background-color: transparent">
                <div style="padding-bottom: 5px">
                    <ion-label v-if="conversation.reservation_id" style="font-weight: bold; font-size: 12px"
                        >Invoice #{{ conversation.reservation_id }}</ion-label
                    >
                    <ion-label v-else style="font-weight: bold; font-size: 12px; font-style: italic"
                        >Inquiry Only</ion-label
                    >
                </div>
                <div style="display: flex; justify-content: space-between">
                    <div style="display: flex; flex-direction: column; width: 100%">
                        <ion-label
                            style="font-weight: bold"
                            :style="[!conversation.subject ? 'font-style: italic;' : '']"
                            >{{ conversation.subject || 'Subject Unavailable' }}</ion-label
                        >
                        <ion-label
                            style="font-size: 14px"
                            :style="[!conversation.last_message_preview ? 'font-style: italic;' : '']"
                            >{{
                                conversation.last_message_preview
                                    ? ' ' + conversation.last_message_preview
                                    : 'Message preview unavailable.'
                            }}</ion-label
                        >
                    </div>
                </div>
            </ion-card-content>
            <ion-chip
                style="
                    background: var(--ion-color-primary);
                    max-width: 100%;
                    max-height: 40px;
                    height: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0px;
                    margin: 0px;
                    border-radius: 0px;
                "
                v-if="currentConversationId == conversation.id"
            >
                <span style="font-weight: bold; color: white"> Currently Selected </span>
            </ion-chip>
        </ion-card>
        <div style="margin: 100px" />
    </modal-base-layout>
</template>

<script lang="ts">
    import { modalController, useIonRouter } from '@ionic/vue';
    import { defineComponent, onMounted, ref } from 'vue';
    import { bicycle, closeOutline, home, restaurant, trailSign, chevronForward } from 'ionicons/icons';
    import { useStore } from '@/store/store';
    import AppAPI from '@/services/AppAPI';
    import router from '@/router';
    import { hideLoading, showLoading } from '@/services/LoadingController';

    export default defineComponent({
        setup(props) {
            const store = useStore();
            const ionRouter = useIonRouter();
            const list = ref([] as any[]);

            const currentConversationId = router.currentRoute.value.params.pathMatch[1]?.toString();

            onMounted(async () => {
                const loading = await showLoading('Loading Conversations...');
                try {
                    const conversations = (await AppAPI.getConversations()).data;
                    list.value = conversations;
                } catch (error) {
                    console.error(error);
                } finally {
                    hideLoading(loading);
                }
            });

            const selectChat = (conversation: any) => {
                //window.location.href = ('/tabs/messages/conversation/' + conversation.id);
                router.replace('/tabs/messages/conversation/' + conversation.id);
                modalController.dismiss();
            };

            return {
                selectChat,
                currentConversationId,
                ionRouter,
                closeOutline,
                store,
                bicycle,
                chevronForward,
                restaurant,
                trailSign,
                home,
                list,
            };
        },
    });
</script>

<style scoped></style>
