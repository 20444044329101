import { useStore } from '@/store/store';
import AppAPI from './AppAPI';
import AppFunctions from './AppFunctions';
import { accessToken } from './Authentication';
import { hideLoading, showLoading } from './LoadingController';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { swapReservationModal } from './ModalController';
import { add, isAfter } from 'date-fns';
import { SplashScreen } from '@capacitor/splash-screen';
import { setupFCM } from './FCM';
import router from '@/router';
import { ref } from 'vue';
import { isProduction } from '@/constants';
import { updateNotificationPermissionStatus } from './Utils';

export async function startup(displayLoading = true) {
    const store = useStore();

    if (!accessToken.value) {
        return;
    }

    let loading = null;
    if (displayLoading) {
        loading = await showLoading('Getting Things Ready...'); //loading...
    }

    try {
        const user = (await AppAPI.getUser()).data;
        if (user) {
            store.user = user;
            const betaTestUsers = process.env.VUE_APP_BETA_TEST_USERS;
            const betaTestUsersArray = betaTestUsers ? betaTestUsers.split(',') : [];
            if ((betaTestUsersArray.includes(user.id.toString()) && !AppFunctions.isImpersonating()) || !isProduction) {
                store.isBetaUser = true;
            } else {
                store.isBetaUser = false;
            }
        }
        setupFCM();
        await updateNotificationPermissionStatus();

        // Removed to improve load time
        //const reservations = (await AppAPI.getAllReservations()).data;
        //store.allReservations = reservations;

        //handle loading reservation, if one selected load it if not show select menu
        const id = await SecureStoragePlugin.get({ key: 'selectedResId' }).catch((error) => {
            console.log('selectedResId', error);
        });
        const savedReservationId = ref(id?.value);

        //check to see if user is on chat page, if so then load reservation related to chat.
        if (router.currentRoute.value.path.includes('conversation')) {
            const reservations = (await AppAPI.getAllReservations()).data;
            store.allReservations = reservations;
            if (store.allReservations.length > 0) {
                for (let i = 0; i < store.allReservations.length; i++) {
                    if (
                        store.allReservations[i].conversation_id ==
                            router.currentRoute.value.params.pathMatch[1]?.toString() ||
                        ''
                    ) {
                        //alert('Switching to Invoice #'+store.allReservations[i].id)
                        savedReservationId.value = store.allReservations[i].id;
                        break;
                    } else {
                        // refresh page on last item
                        if (i == store.allReservations.length - 1) {
                            router.replace('/');
                        }
                    }
                }
            }
        }

        if (savedReservationId.value) {
            try {
                await store.changeSelectedReservationId(savedReservationId.value);
                const reservation = (await AppAPI.getReservationData(store.selectedReservationId)).data;
                store.reservationData = reservation;
                store.selectedTechReservationId = reservation.tech_reservations[0].id || '';

                let reservationEndDate = new Date(reservation.to_date);
                reservationEndDate = add(reservationEndDate, { days: 1 });
                if (
                    isAfter(new Date(), reservationEndDate) &&
                    !router.currentRoute.value.path.includes('conversation')
                ) {
                    throw new Error('Stay has expired. Please select a new one.');
                }

                await AppFunctions.getHostedForms();

                await new Promise((resolve) => setTimeout(resolve, 1000));
                if (loading) {
                    hideLoading(loading);
                }
                SplashScreen.hide();
            } catch (error) {
                await new Promise((resolve) => setTimeout(resolve, 1000));
                if (loading) {
                    hideLoading(loading);
                }
                SplashScreen.hide();
                store.changeSelectedReservationId('');
                await swapReservationModal(store.allReservations.length > 0).catch((error) => {
                    console.error(error);
                });
            }
        } else {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            if (loading) {
                hideLoading(loading);
            }
            SplashScreen.hide();
            await swapReservationModal(store.allReservations.length > 0).catch((error) => {
                console.error(error);
            });
        }
    } catch (error) {
        console.error(error);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        if (loading) {
            hideLoading(loading);
        }
        SplashScreen.hide();
    }

    if (loading) {
        hideLoading(loading);
    }
}
