<template>
    <modal-base-layout title="Shares" :hideHeader="QRCode">
        <ion-list lines="full" v-for="(item, index) in sharedUsers" :key="index">
            <ion-item>
                <ion-icon :icon="personCircleOutline" slot="start" />
                <ion-label>
                    <h2>{{ item.name }}</h2>
                    <p>{{ item.email }}</p>
                </ion-label>
                <ion-icon
                    slot="end"
                    style="margin: 0 10px; cursor: pointer"
                    :icon="mailOutline"
                    @click="sendLoginLink(item.id)"
                />
                <ion-icon
                    v-if="canShare"
                    slot="end"
                    style="margin: 0 10px; cursor: pointer"
                    :icon="shareOutline"
                    @click="shareUrl(item)"
                ></ion-icon>
                <ion-icon slot="end" style="cursor: pointer" :icon="ellipsisVertical" @click="editGuest(item.id)" />
            </ion-item>
        </ion-list>

        <div v-if="sharedUsers.length <= 0 && !isLoading" style="margin-top: 25%">
            <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
                <ion-icon :icon="shareSocialOutline" style="width: 50px; height: 50px" />
                <ion-label style="margin-top: 10px; font-size: larger; font-weight: 700">No Shares Added</ion-label>
                <p style="margin-top: 10px; max-width: 75%; text-wrap: balance; text-align: center">
                    Shares will only have access to view invoice
                    <span style="font-weight: bold">#{{ store.selectedReservationId }}</span
                    >.
                </p>
            </div>
        </div>

        <div
            v-if="QRCode"
            style="
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 1021;
            "
        >
            <div
                style="
                    position: fixed;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background-color: black;
                    opacity: 0.9;
                    z-index: 1;
                "
            />
            <div
                style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    z-index: 1022 !important;
                "
            >
                <ion-button
                    color="light"
                    @click="QRCode = null"
                    style="
                        z-index: 1023;
                        top: 25px;
                        right: 25px;
                        font-size: large;
                        position: absolute;
                        padding-top: env(safe-area-inset-top) !important;
                    "
                >
                    Close QR Code
                </ion-button>
                <div v-html="QRCode" />
                <ion-label
                    color="light"
                    style="
                        z-index: 1023;
                        bottom: 25px;
                        font-size: large;
                        position: absolute;
                        padding-top: env(safe-area-inset-bottom) !important;
                        text-wrap: balance;
                        width: 75%;
                    "
                >
                    {{ QRCodeText }}
                </ion-label>
            </div>
        </div>
    </modal-base-layout>

    <ion-fab vertical="bottom" horizontal="end" slot="fixed" v-if="!QRCode">
        <ion-fab-button @click="createSharedUser">
            <ion-icon :icon="personAddOutline" />
        </ion-fab-button>
    </ion-fab>
    <ion-fab vertical="bottom" horizontal="start" slot="fixed" v-if="!QRCode">
        <ion-fab-button
            @click="
                basicAlert(
                    'About',
                    '',
                    'Shared users are allowed access only to reservation information. Features such as the inbox, payments, reviews, notifications, and rental agreements are not available. Shared users can be added or removed any time.'
                )
            "
        >
            <ion-icon :icon="helpOutline" />
        </ion-fab-button>
    </ion-fab>
</template>

<script lang="ts">
    import { alertController, useIonRouter } from '@ionic/vue';
    import { computed, defineComponent, onMounted, ref } from 'vue';
    import {
        personCircleOutline,
        chevronForwardCircleOutline,
        mailOutline,
        personAddOutline,
        peopleCircleOutline,
        ellipsisVertical,
        shareOutline,
        helpOutline,
        shareSocialOutline,
    } from 'ionicons/icons';
    import { useStore } from '@/store/store';
    import AppAPI from '@/services/AppAPI';
    import { errorToast, successToast } from '@/services/ToastController';
    import { advancedAlert, basicAlert } from '@/services/AlertController';
    import { showLoading, hideLoading } from '@/services/LoadingController';
    import { Share } from '@capacitor/share';

    export default defineComponent({
        setup(props) {
            const store = useStore();
            const ionRouter = useIonRouter();
            const sharedUsers = ref([] as any);
            const isLoading = ref(false);
            const subHeader = ref(`Will only have access to view invoice #${store.selectedReservationId}.`);
            const canShare = ref(false);
            const QRCode = ref();
            const QRCodeText = ref(`Scan QR Code to Login to Staylio`);

            onMounted(async () => {
                const loading = await showLoading('Loading...');
                isLoading.value = true;
                canShare.value = (await Share.canShare()).value;
                try {
                    sharedUsers.value = (await AppAPI.getSharedUsers()).data;
                } catch (error: any) {
                    console.log(error);
                } finally {
                    hideLoading(loading);
                    isLoading.value = false;
                }
            });

            const sendLoginLink = async (id: any, isJustCreated = false) => {
                const guestEmail = sharedUsers.value.find((guest: any) => guest.id === id).email;
                let message = `Are you sure you want to send a login link to ${guestEmail}?`;

                if (isJustCreated) {
                    message = `Do you want to send a login link to ${guestEmail}?`;
                }

                const alert = await alertController.create({
                    header: 'Send Staylio Login Link',
                    message,
                    inputs: [
                        {
                            name: 'message',
                            type: 'textarea',
                            placeholder: 'Optional Message',
                        },
                    ],
                    buttons: [
                        { text: 'Cancel', role: 'cancel' },
                        {
                            text: 'Send',
                            handler: async (data: any) => {
                                try {
                                    await AppAPI.sendSharedUserLogin(id, data.message);
                                    successToast(`Login link sent to ${guestEmail}`);
                                } catch (error: any) {
                                    const message =
                                        error.response.data.error ||
                                        'There was an issue sending the login link. Please try again.';
                                    errorToast(message);
                                }
                            },
                        },
                    ],
                });

                await alert.present();
            };

            const getAlertInputs = (guest: any = { name: '', email: '' }) => [
                { name: 'name', type: 'text' as const, placeholder: 'Name', value: guest.name },
                { name: 'email', type: 'email' as const, placeholder: 'Email', value: guest.email },
            ];

            const editGuest = async (id: any) => {
                const guest = sharedUsers.value.find((guest: any) => guest.id === id);
                const alertInputs = getAlertInputs(guest);

                const alert = await alertController.create({
                    header: 'Edit a Share',
                    subHeader: subHeader.value,
                    inputs: alertInputs,
                    buttons: [
                        {
                            text: 'Delete',
                            cssClass: 'alert-button-delete',
                            handler: async () => {
                                try {
                                    await AppAPI.deleteSharedUser(id);
                                } catch (error: any) {
                                    const message =
                                        error.response.data.error ||
                                        'There was an issue deleting the user. Please try again.';
                                    errorToast(message);
                                }
                                sharedUsers.value = (await AppAPI.getSharedUsers()).data;
                            },
                        },
                        { text: 'Cancel', role: 'cancel' },
                        {
                            text: 'Save',
                            handler: async (data: any) => {
                                try {
                                    await AppAPI.updateSharedUsers(id, data.name, data.email);
                                } catch (error: any) {
                                    const response = error.response.data;
                                    let message = '';

                                    for (const [key, value] of Object.entries(response) as any) {
                                        message += `${key.charAt(0).toUpperCase() + key.slice(1)}: ${value.join(', ')}\n`;
                                    }

                                    const errorMessage =
                                        message.trim() ||
                                        error.response.data.error ||
                                        'There was an issue updating the user. Please try again.';
                                    errorToast(errorMessage);
                                }
                                sharedUsers.value = (await AppAPI.getSharedUsers()).data;
                            },
                        },
                    ],
                });

                await alert.present();
            };

            const createSharedUser = async () => {
                const alertInputs = getAlertInputs();

                const alert = await alertController.create({
                    header: 'Create a Share',
                    subHeader: subHeader.value,
                    inputs: alertInputs,
                    buttons: [
                        { text: 'Cancel', role: 'cancel' },
                        {
                            text: 'Create',
                            handler: async (data: any) => {
                                try {
                                    await AppAPI.createSharedUser(data.name, data.email);
                                } catch (error: any) {
                                    const response = error.response.data;
                                    let message = '';

                                    for (const [key, value] of Object.entries(response) as any) {
                                        message += `${key.charAt(0).toUpperCase() + key.slice(1)}: ${value.join(', ')}\n`;
                                    }

                                    const errorMessage =
                                        message.trim() ||
                                        error.response.data.error ||
                                        'There was an issue adding the user. Please try again.';
                                    errorToast(errorMessage);
                                }
                                sharedUsers.value = (await AppAPI.getSharedUsers()).data;
                                const id = sharedUsers.value.find((guest: any) => guest.email === data.email).id;
                                await sendLoginLink(id, true);
                            },
                        },
                    ],
                });

                await alert.present();
            };

            const shareUrl = async (item: any) => {
                if (!canShare.value) {
                    errorToast('Sharing is not supported on this device');
                    return;
                }
                const loginData = (await AppAPI.getSharedUserLogin(item.id)).data;
                const buttons = [
                    {
                        text: 'Display QR Code',
                        handler: async () => {
                            QRCodeText.value = `Scan QR Code to Login to Staylio as ${item.name || 'Shared User'}`;
                            QRCode.value = loginData.qr_code;

                            try {
                                QRCode.value = QRCode.value.replace('<img', '<img style="border-radius: 15px;"');
                            } catch (error: any) {
                                QRCode.value = loginData.qr_code || 'Error displaying QR Code';
                            }
                        },
                    },
                    {
                        text: 'Share Login Link',
                        handler: async () => {
                            try {
                                await Share.share({
                                    title: `Hi ${item.name},\n\nYou have been added to reservation #${store.selectedReservationId} on Staylio. Click the link below to get started. \n`,
                                    url: loginData.magic_link,
                                });
                            } catch (error: any) {
                                console.log(error);
                            }
                        },
                    },
                ];

                await advancedAlert('Send Login', '', 'Send a login link message or display a login QR code.', buttons);
            };

            return {
                ionRouter,
                store,
                sharedUsers,
                personCircleOutline,
                chevronForwardCircleOutline,
                mailOutline,
                personAddOutline,
                peopleCircleOutline,
                ellipsisVertical,
                isLoading,
                shareOutline,
                canShare,
                QRCode,
                helpOutline,
                QRCodeText,
                shareSocialOutline,
                editGuest,
                createSharedUser,
                sendLoginLink,
                shareUrl,
                basicAlert,
            };
        },
    });
</script>

<style scoped></style>
