import router from '@/router';
import { useStore } from '@/store/store';
import { Capacitor } from '@capacitor/core';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { pushNotificationReminderModal, notificationListModal } from '@/services/ModalController';
import { SplashScreen } from '@capacitor/splash-screen';
import { useIonRouter } from '@ionic/vue';
import AppAPI from './AppAPI';
import AppFunctions from './AppFunctions';

function setupFCM() {
    const store = useStore();

    if (Capacitor.getPlatform() === 'web' || AppFunctions.isImpersonating()) {
        //console.log('Not setting up Push Notifications');
        return;
    }

    registerNotifications();

    PushNotifications.addListener('registration', async (token: Token) => {
        store.pushNotificationToken = token.value;
        console.log('FCM Token: ' + token.value);
    });

    PushNotifications.addListener('registrationError', (error: any) => {
        console.log('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification));
    });
}

async function registerNotifications() {
    const store = useStore();
    let permStatus = await PushNotifications.checkPermissions();
    if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
    }
    if (permStatus.receive !== 'granted' && !store.pushNotificationReminderHidden) {
        await pushNotificationReminderModal();
    }
    await PushNotifications.register();
}

async function getNotificaitons() {
    if (Capacitor.getPlatform() === 'web') {
        return;
    }
}

async function addPushNotificationTapOpen() {
    if (Capacitor.getPlatform() === 'web') {
        return;
    }
    PushNotifications.addListener('pushNotificationActionPerformed', async (notification: ActionPerformed) => {
        const data = notification.notification.data;
        const inputValue = notification.inputValue || '';
        data.inputValue = inputValue;
        if (!data.inputValue) {
            AppAPI.setPushNotificationOpened();
        }
        await handlePushNotificationOpen(data);
    });
}

async function handlePushNotificationOpen(data: any) {
    if (data.inputValue) {
        return;
    }

    if (data.conversation_id && !data.message_id) {
        router.replace('/tabs/messages/conversation/' + data.conversation_id);
    } else if (data.conversation_id && data.message_id) {
        if (router.currentRoute.value.path.includes('/tabs/messages/conversation/')) {
            router.replace(
                '/tabs/reservation/redirect?url=/tabs/messages/conversation/' + data.conversation_id /*+
                    '?message_id=' +
                    data.message_id*/
            );
        } else {
            router.replace(
                '/tabs/messages/conversation/' + data.conversation_id /*+ '?message_id=' + data.message_id*/
            );
        }
    } else {
        if (data.staylio_push_notification_id) {
            setTimeout(async () => {
                await notificationListModal(data.staylio_push_notification_id);
            }, 100);
        }
    }
}

export { setupFCM, getNotificaitons, addPushNotificationTapOpen, handlePushNotificationOpen };
