import { useStore } from '@/store/store';
import AppFunctions from '@/services/AppFunctions';

const whitelistDomains = [
    '.google.com',
    '.facebook.com',
    '.page',
    '.dropbox.com',
    'onedrive.live.com',
    'staylio.com',
    'lodgix.com',
];

export function checkUrl(url: string) {
    const isWhitelisted = whitelistDomains.some((domain) => url.includes(domain));
    return isWhitelisted;
}

export function isHTTPS(url: string) {
    return url.startsWith('https://');
}

export async function updateNotificationPermissionStatus() {
    const store = useStore();
    const isPushNotificationsEnabled = await AppFunctions.checkPushNotificationPermission();
    store.isPushNotificationsEnabled = isPushNotificationsEnabled;
}
