<template>
    <div v-if="selectedProperty.rental_property.cancellation_policy">
        <ion-card>
            <ion-card-header>
                <ion-label class="section-title">Cancellation Policy</ion-label>
            </ion-card-header>
            <ion-card-content>
                <ion-item lines="none">
                    <ion-label style="margin-top: 10px">
                        <div v-html="generateHtml(selectedProperty.rental_property.cancellation_policy.text)" />
                    </ion-label>
                </ion-item>
            </ion-card-content>
        </ion-card>
    </div>
</template>

<script lang="ts">
    import { defineComponent, computed } from 'vue';
    import { useStore } from '@/store/store';
    import AppFunctions from '@/services/AppFunctions';

    export default defineComponent({
        setup() {
            const store = useStore();

            const selectedProperty = computed(() => {
                return AppFunctions.getSelectedPropertyData();
            });

            const generateHtml = (value: string) => {
                if (value && !value.includes('<')) {
                    value = value.replace(/\n/g, '<br />');
                }
                return value || 'N/A';
            };

            return {
                selectedProperty,
                store,
                generateHtml,
            };
        },
    });
</script>
