<template>
    <modal-base-layout :title="title" :showCloseButton="false">
        <ion-card>
            <ion-card-header>
                <ion-label class="section-title">Device Offline</ion-label>
            </ion-card-header>
            <ion-card-content>
                <ion-item lines="none">
                    <ion-label> You are currently offline. Please check your internet connection. </ion-label>
                </ion-item>
                <ion-button
                    v-if="getPlatform() != 'web'"
                    style="justify-content: center; display: flex; align-items: center; padding: 0 10px 10px"
                    @click="openDeviceSettings()"
                >
                    Open Device Settings
                </ion-button>
            </ion-card-content>
        </ion-card>

        <template v-if="store.selectedReservationId">
            <ion-card>
                <ion-card-header>
                    <ion-label class="section-title">Viewing Reservation in Offline Mode</ion-label>
                </ion-card-header>
                <ion-card-content>
                    <ion-list lines="full">
                        <ion-item>
                            <ion-label>
                                When using Staylio in offline mode, you'll see the last viewed reservation. Please note
                                that the information may be outdated, as it reflects the details from the last time the
                                app was connected to the internet.
                            </ion-label>
                        </ion-item>
                        <ion-item>
                            <ion-label slot="start">Property Name</ion-label>
                            <ion-label slot="end">{{ selectedProperty.rental_property.name }}</ion-label>
                        </ion-item>

                        <ion-item lines="none">
                            <ion-label slot="start">Invoice #</ion-label>
                            <ion-label slot="end">{{ store.selectedReservationId }}</ion-label>
                        </ion-item>
                    </ion-list>
                </ion-card-content>
            </ion-card>
            <CheckInOutCard isCheckIn />
            <PropertyAddressDirections hideMap showCopy />
            <WifiDetails />
            <DoorCode />
            <HouseManual />

            <PropertyCustomVariables :location="1" />
            <PropertyCustomVariables :location="2" />
            <PropertyCustomVariables :location="3" />

            <CheckInOutCard isCheckOut />
            <CheckOutTasks />

            <ion-card>
                <ion-card-header>
                    <ion-label class="section-title">Company Details</ion-label>
                </ion-card-header>
                <ion-card-content>
                    <ion-list lines="full">
                        <ion-item v-if="store.reservationData.company.title">
                            <ion-label slot="start">Company Name</ion-label>
                            <ion-label slot="end">{{ store.reservationData.company.title }}</ion-label>
                        </ion-item>
                        <ion-item v-if="store.reservationData.company.public_phone">
                            <ion-label slot="start">Phone Contact</ion-label>
                            <ion-label slot="end">{{ store.reservationData.company.public_phone }}</ion-label>
                        </ion-item>
                        <ion-item v-if="store.reservationData.company.public_email">
                            <ion-label slot="start">Email Contact</ion-label>
                            <ion-label slot="end">{{ store.reservationData.company.public_email }}</ion-label>
                        </ion-item>
                        <ion-item lines="none" v-if="store.reservationData.company.public_website">
                            <ion-label slot="start">Company Website</ion-label>
                            <ion-label slot="end">{{ store.reservationData.company.public_website }}</ion-label>
                        </ion-item>
                    </ion-list>
                </ion-card-content>
            </ion-card>
        </template>
    </modal-base-layout>
</template>

<script lang="ts">
    import { modalController, useIonRouter } from '@ionic/vue';
    import { computed, defineComponent, onMounted, ref } from 'vue';
    import { useStore } from '@/store/store';
    import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
    import { Capacitor } from '@capacitor/core';
    import WifiDetails from '@/components/SelectedPropertyComponents/WifiDetails.vue';
    import DoorCode from '@/components/SelectedPropertyComponents/DoorCode.vue';
    import PropertyAddressDirections from '@/components/SelectedPropertyComponents/PropertyAddressDirections.vue';
    import CheckInOutCard from '@/components/SelectedPropertyComponents/CheckInOutCard.vue';
    import AppFunctions from '@/services/AppFunctions';
    import HouseManual from '../SelectedPropertyComponents/HouseManual.vue';
    import PropertyCustomVariables from '../SelectedPropertyComponents/PropertyCustomVariables.vue';
    import { Network } from '@capacitor/network';
    import CheckOutTasks from '@/components/SelectedPropertyComponents/CheckOutTasks.vue';

    export default defineComponent({
        components: {
            WifiDetails,
            DoorCode,
            PropertyAddressDirections,
            CheckInOutCard,
            HouseManual,
            PropertyCustomVariables,
            CheckOutTasks,
        },
        setup(props) {
            const store = useStore();
            const ionRouter = useIonRouter();

            const selectedProperty = computed(() => {
                return AppFunctions.getSelectedPropertyData();
            });

            const title = computed(() => {
                return store.selectedReservationId ? `Invoice #${store.selectedReservationId}` : 'Device Offline';
            });

            const openDeviceSettings = async () => {
                NativeSettings.open({
                    optionAndroid: AndroidSettings.ApplicationDetails,
                    optionIOS: IOSSettings.App,
                });
            };

            const getPlatform = () => {
                return Capacitor.getPlatform();
            };

            onMounted(async () => {
                await store.getBackupReservationData();
            });

            setInterval(async () => {
                const status = await Network.getStatus();
                store.isOffline = !status.connected;
                if (!store.isOffline) {
                    modalController.dismiss();
                    document.location.href = 'index.html';
                }
            }, 1000);

            return {
                ionRouter,
                store,
                title,
                selectedProperty,
                openDeviceSettings,
                getPlatform,
            };
        },
    });
</script>

<style scoped></style>
